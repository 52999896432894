










































































































































































































































































































































































import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Vue from 'vue';
import TopBg from '../fair/component/detailTopBg.vue';
import { ResponseCode } from '~/util/constant';
import { message } from '~/util/common';
import { bucketCdnHost, bucketHost } from '~/util/common';
import { serviceObj } from '~/pages/fair/service';
import moment from 'moment';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'Booth',
  layout: 'layout2',
  // 校验params参数
  validate(ctx) {
    const id = _.get(ctx, 'params.id', null);
    return /^\d+$/.test(id);
  },
  components: {
    TopBg,
  },
  async asyncData(ctx) {
    const { type = 'booth' } = ctx.query;
    const id = _.get(ctx, 'params.id', null);

    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${id}`),
      ctx.$axios.get(`/api/exhibition/boothInfo/${id}`),
    ]);

    const [fairRes, boothRes] = res;

    let fair: Record<string, any> = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      if (fairRes.data.boothInfo) {
        fairRes.data.boothInfo = JSON.parse(fairRes.data.boothInfo);
      } else {
        fairRes.data.boothInfo = [];
      }
      fair = fairRes.data || {};
      console.log(fair);
    }

    // 展会价格
    let booth = {};
    if (boothRes.code === ResponseCode.SUCCESS) {
      booth = boothRes.data;
    }
    return {
      fair,
      booth,
      type,
    };
  },
  data(): Record<string, any> {
    return {
      fair: {},
      booth: {},
      isLoading: false,
      ruleForm: {
        companyName: '',
        linkman: '',
        remark: '',
        email: '',
        boothType: undefined,
        linkmanPhoneNumber: '',
        content: '',
        peopleNum: undefined,
      },
      rules: {
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        linkman: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
        ],
        boothType: [
          { required: true, message: '价格不能为空', trigger: 'blur' },
        ],
        linkmanPhoneNumber: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '展品内容不能为空', trigger: 'blur' },
        ],
        peopleNum: [
          { required: true, message: '人数不能为空', trigger: 'blur' },
        ],
      },
      cooperateTypeList: [
        {
          value: 13,
          label: '展团行程服务',
          check: false,
          url: require('@/assets/images/apply_zhanwei.jpg'),
        },
        {
          value: 11,
          label: '酒店预订服务',
          check: false,
          url: require('@/assets/images/apply_jiudian.jpg'),
        },
        {
          value: 1,
          label: '签证办理服务',
          check: false,
          url: require('@/assets/images/apply_qianzheng.jpg'),
        },
        {
          value: 10,
          label: '机票预订服务',
          check: false,
          url: require('@/assets/images/apply_jipiao.jpg'),
        },
      ],
      mark: '',
      tripPrice: '',
    };
  },
  filters: {
    industry(data: string): string {
      try {
        return data.split(',')[0] + '展会';
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + '展会';
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  computed: {
    title(): string {
      let t = '展位';
      if (this.type === 'booth') {
        t = '展位';
      } else if (this.type === 'card') {
        t = '邀请函';
      } else if (this.type === 'build') {
        t = '展位搭建';
      }
      return t;
    },
    days(): number {
      const { startTime, endTime } = this.fair;
      return moment(endTime).diff(moment(startTime), 'days') + 4;
    },
  },
  created() {
    // console.log(this.$route.query.success);
    if (this.$route.query.success) {
      this.mark = this.$route.query.success;
    }
    if (process.client) {
      const boothForm = sessionStorage.getItem('boothForm');
      try {
        this.ruleForm = JSON.parse(boothForm as any) || {};
      } catch (error) {
        console.error(error);
      }
      if (boothForm) {
        sessionStorage.removeItem('boothForm');
      }
    }
  },
  mounted() {
    const phone = this.$cookies.get('phoneNumber') || '';
    if (phone) {
      this.$set(this.ruleForm, 'linkmanPhoneNumber', phone);
    }

    switch (this.fair.area) {
      case '日本':
        this.tripPrice = '12000元/人机票费用：按实际出票当天价格收取';
        break;
      case '美国':
        this.tripPrice = '18000元/人机票费用：按实际出票当天价格收取';
        break;
      case '德国':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '新加坡':
        this.tripPrice = '12000元/人机票费用：按实际出票当天价格收取';
        break;
      case '英国':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '意大利':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '俄罗斯':
        this.tripPrice = '15000元/人机票费用：按实际出票当天价格收取';
        break;
      case '澳大利亚':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '韩国':
        this.tripPrice = '12000元/人机票费用：按实际出票当天价格收取';
        break;
      case '印度':
        this.tripPrice = '15000元/人机票费用：按实际出票当天价格收取';
        break;
      case '墨西哥':
        this.tripPrice = '21000元/人机票费用：按实际出票当天价格收取';
        break;
      case '巴西':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '土耳其':
        this.tripPrice = '15000元/人机票费用：按实际出票当天价格收取';
        break;
      case '法国':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '西班牙':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '越南':
        this.tripPrice = '9000元/人机票费用：按实际出票当天价格收取';
        break;
      case '印尼':
        this.tripPrice = '10000元/人机票费用：按实际出票当天价格收取';
        break;
      case '泰国':
        this.tripPrice = '11000元/人机票费用：按实际出票当天价格收取';
        break;
      case '哈萨克斯坦':
        this.tripPrice = '12000元/人机票费用：按实际出票当天价格收取';
        break;
      case '乌兹别克斯坦':
        this.tripPrice = '12000元/人机票费用：按实际出票当天价格收取';
        break;
      case '马来西亚':
        this.tripPrice = '12000元/人机票费用：按实际出票当天价格收取';
        break;
      case '菲利宾':
        this.tripPrice = '12000元/人机票费用：按实际出票当天价格收取';
        break;
      case '荷兰':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '瑞士':
        this.tripPrice = '23000元/人机票费用：按实际出票当天价格收取';
        break;
      case '波兰':
        this.tripPrice = '19000元/人机票费用：按实际出票当天价格收取';
        break;
      default:
        // 处理默认情况
        this.tripPrice = '';
        break;
    }
  },
  methods: {
    moment,
    ...mapState({
      user: (state: any) => state.global.user,
    }),
    submitForm(formName: string) {
      const $form = this.$refs[formName] as ElForm;
      $form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.handleApplyBooth();
        } else {
          return false;
        }
      });
    },
    async handleApplyBooth() {
      const { id } = this.$route.params;
      const res: any = await this.$axios.post('/api/exhibition/applyBooth', {
        ...this.ruleForm,
        exhibitionId: +id,
        serviceTypeList: [13],
      });
      this.isLoading = false;
      if (res.code === ResponseCode.SUCCESS) {
        const $form = this.$refs.rule as ElForm;
        $form?.resetFields();
        await message.call(this, {
          delay: 3000,
          type: 'success',
          title: '展团行程申请成功',
          msg: '专业客服将在1-2个工作日内电话与您联系。',
        });
        this.$router.push(`/exhibition/${id}.html`);
        // this.$message.success('展位申请成功.');
      }
    },
    querySearchAsync: _.debounce(async function (
      str: string,
      cb: (val: any[]) => void
    ) {
      // @ts-ignore
      const { $axios } = this;
      let data = [];
      if (str && str.trim()) {
        const res: any = await $axios.post(
          '/api/companyApi/keyWord',
          str.trim(),
          {
            headers: {
              'Content-Type': 'text/plain',
            },
          }
        );
        data = res.data.map((r: string) => ({ value: r }));
      } else {
        data = [];
      }
      cb(data);
    },
    300),
  },
  head(): Record<string, any> {
    const { exhibitionName, area } = this.$data.fair;
    let title = `${exhibitionName}_展团行程-聚展`;
    let keywords = `${area}展位搭建,搭建设计,搭建方案`;
    let description = `聚展网专业办理${area}展团行程、为用户提供专业的展会服务`;
    return {
      title,
      meta: [
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
    };
  },
});
